import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const LogoBlock = ({ logo }) => {
  return (
    <div key={logo.id} className="bt">
      {/* {JSON.stringify(logo)} */}

      <div className="mw6">
        {logo.name && <h2>{logo.name}</h2>}
        {logo.subtitle && <div>{logo.subtitle}</div>}
        {logo.description && <p>{logo.description}</p>}
      </div>

      <div className="cf">
        {logo.variants.map(variant => (
          <div className="br3 bg-white ba dark-gray b--black-10 mv4 w-100 w-50-m w-33-l">
            <div className="pa5">
              <img
                className="db"
                src={variant.vector.url || variant.rgbColour.url}
                alt={variant.name}
              />
            </div>
            <div className="pa3 bg-black-10 mt3 bt b--black-10">
              <h3 className="fw5 f5 f4-ns mb0">{variant.name}</h3>

              <ul className="pl0 mt2 list">
                {variant.rgbColour && (
                  <li className="bt b--black-20 pv2">
                    <a className="link" href={variant.rgbColour.url}>
                      RGB colour ({variant.rgbColour.format})
                    </a>
                  </li>
                )}
                {variant.vector && (
                  <li className="bt b--black-20 pv2">
                    <a className="link" href={variant.vector.url}>
                      Vector ({variant.vector.format})
                    </a>
                  </li>
                )}
                {variant.spotColours.map(spot => (
                  <li className="bt b--black-20 pv2">
                    <a className="link db" href={spot.url}>
                      Spot colour ({spot.format})
                    </a>
                  </li>
                ))}
                {variant.processColours.map(process => (
                  <li className="bt pv2">
                    <a className="link db" href={process.url}>
                      Process colour ({process.format})
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const LogosPage = ({ data }) => {
  return (
    <Layout>
      <HelmetDatoCms seo={data.datoCmsLogosPage.seoMetaTags} />

      <header className="mw8-l center ph3 ph0-l mt4 mt5-l">
        <h1 className="text-h1">{data.datoCmsLogosPage.title}</h1>

        {/* <div
          className="mw7"
          dangerouslySetInnerHTML={{
            __html: data.datoCmsColoursPage.introNode.childMarkdownRemark.html
          }}
        /> */}
      </header>

      <section className="mw8-l center ph3 ph0-l mt5">
        {data.allDatoCmsLogo.edges.map(({ node: logo }) => (
          <LogoBlock logo={logo} />
        ))}
      </section>
    </Layout>
  )
}

export default LogosPage

export const query = graphql`
  query LogosQuery {
    datoCmsSite {
      globalSeo {
        siteName
      }
    }
    datoCmsLogosPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
    }
    allDatoCmsLogo {
      edges {
        node {
          name
          description
          variants {
            name
            vector {
              format
              url
            }
            spotColours {
              format
              url
            }
            rgbColour {
              format
              url
            }
            processColours {
              format
              url
            }
          }
        }
      }
    }
  }
`
